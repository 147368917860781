<header id="HeaderBox" class="p-3">
  <div class="d-flex flex-wrap justify-content-between">
    <div id="HeaderBox1">
      <div id="LogoBox" class="mb-3" (click)="appConfig.setHomepage()">
        <img class="img-fluid pointer" [alt]="appDataService.projectTitle" src="s/logo" />
      </div>
      <div id="TitleBox">
        @if (appDataService.projectTitle || appDataService.pageTitle) {
          <h1>
            @if (appDataService.projectTitle) {
              <span id="ProjectTitle" (click)="appConfig.setHomepage()">
                <a href="">{{appDataService.projectTitle}}</a>
              </span>
            }
            @if (appDataService.projectTitle && appDataService.pageTitle) {
              <span class="TitleSeparator"> - </span>
            }
            <span id="PageTitle">{{appDataService.pageTitle ? appDataService.pageTitle : '' | translate}}</span>
          </h1>
        }
      </div>
    </div>
    <div id="HeaderBox2">
      <views-user id="UserBox"></views-user>
    </div>
  </div>
</header>
